/* SIDEBAR (child) — no fixed positioning, no transform. 
   The parent .sidebar-container in Frame.css manages layout. */

.sidebar {
    /* Fill parent's width/height, which is 250px wide & 100% tall */
    width: 100%;
    height: 100%;
    background-color: #1a1a1a;
    color: white;

    /* Layout for content inside sidebar */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0;
    /* can adjust to your preference */
}

/* Navigation section */
.nav {
    padding: 20px;
}

.nav button {
    display: block;
    width: 100%;
    background-color: transparent;
    color: white;
    border: none;
    padding: 15px 10px;
    margin: 10px 0;
    cursor: pointer;
    font-size: 18px;
    text-align: left;
}

.nav button:hover {
    background-color: crimson;
}

/* Logout button */
.out {
    width: 100%;
}

/* User info at bottom */
.user-info {
    padding: 20px;
    background-color: #333;
    text-align: center;
    cursor: pointer;
    border-top: 1px solid #444;
}

.user-info span {
    color: white;
    font-size: 16px;
}

.user-info:hover {
    background-color: crimson;
}
/* Overall container styling */
.sandbox-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 75vh;
    /* Full viewport height */
    padding: 20px;
    box-sizing: border-box;
    background-color: #121212;
    /* Dark background */
    color: #ffffff;
}

/* Header styling */
.sandbox-header {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #00bcd4;
    /* Cyan header text */
    text-align: center;
}

/* Terminal container styling */
.sandbox-terminal {
    font-family: monospace;
    background-color: #000;
    color: #fff;
    width: 100%;
    flex-grow: 1;
    /* Expands to take up remaining space */
    display: flex;
    flex-direction: column;
    border: 1px solid #333;
    border-radius: 8px;
    padding: 10px;
    box-sizing: border-box;
}

/* Output container */
.sandbox-output {
    flex-grow: 1;
    /* Pushes the input to the bottom */
    overflow-y: auto;
    margin-bottom: 10px;
    padding-right: 10px;
    /* Add padding for scrollbar */
}

/* Individual message styling */
.sandbox-message {
    margin: 5px 0;
}

/* Different message types */
.sandbox-user {
    color: #0f0;
    /* Green for user input */
}

.sandbox-error {
    color: #f00;
    /* Red for errors */
}

.sandbox-system {
    color: #fff;
    /* White for system messages */
}

/* Input container styling */
.sandbox-input-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    /* Space between input and button */
}

/* Input field styling */
.sandbox-input {
    flex-grow: 1;
    background-color: #333;
    color: #0f0;
    border: none;
    border-radius: 4px;
    outline: none;
    font-family: monospace;
    font-size: 16px;
}

/* Send button styling */
.sandbox-send-btn {
    padding: 10px 15px;
    margin-bottom: 15px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: monospace;
}

.sandbox-send-btn:hover {
    background-color: #0056b3;
}

.sandbox-send-btn:active {
    background-color: #003f7f;
}
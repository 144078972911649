/* Container for the entire table */
.table-container {
    max-height: 80vh;
    overflow-y: auto;
    overflow-x: auto;
    border: 1px solid var(--form-border-color);
    border-radius: 5px;
    padding: 10px;
    background-color: var(--background-color);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
    font-family: Arial, sans-serif;
    font-size: 14px;
    color: var(--text-color);
}

/* Styling table headers */
th {
    background-color: var(--primary-color);
    color: var(--background-color);
    padding: 8px;
    text-align: center;
    font-weight: bold;
    border-bottom: 2px solid var(--form-border-color);
    position: sticky;
    top: 0;
    z-index: 1;
}

/* Styling table rows and columns */
td {
    padding: 8px;
    border-bottom: 1px solid var(--form-border-color);
    white-space: nowrap;
    text-align: center;
    color: var(--text-color);
}

/* Alternating row colors */
tr:nth-child(even) {
    background-color: var(--table-bg-color);
}

tr:nth-child(odd) {
    background-color: var(--popup-bg-color);
}

/* Hover effect for rows */
tr:hover {
    background-color: var(--primary-hover-color);
    color: var(--background-color);
}

/* Customize scrollbar styling */
.table-container::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

.table-container::-webkit-scrollbar-thumb {
    background-color: var(--primary-color);
    border-radius: 5px;
}

.table-container::-webkit-scrollbar-thumb:hover {
    background-color: var(--primary-hover-color);
}

.table-container::-webkit-scrollbar-track {
    background-color: var(--form-border-color);
}

/* Styling for numbers to be right-aligned */
td[data-type="number"] {
    text-align: right;
}

/* Set maximum width for certain columns */
th,
td {
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
}

th,
td {
    min-width: 100px;
}

/* Special styling for the "Employee" column to be wider */
th.employee,
td.employee {
    max-width: 200px;
    text-align: left;
}

/* Special styling for date columns (timestamp) */
th.timestamp,
td.timestamp {
    max-width: 180px;
}

/* Responsive adjustments */
@media (max-width: 768px) {

    td,
    th {
        padding: 6px;
        font-size: 12px;
    }
}
.dashboard-container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.table-section {
    background-color: var(--table-bg-color);
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h2 {
    margin-bottom: 20px;
    font-size: 24px;
    color: var(--text-color);
}

.week-carousel {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    /* If you want snap-scrolling */
}

.week-summary-card {
    flex: 0 0 auto;
    width: 300px;
    margin-right: 1rem;
    scroll-snap-align: start;
    /* If you're doing snap-scrolling */

    border: 1px solid var(--form-border-color);
    border-radius: 8px;
    padding: 16px;
    background-color: var(--popup-bg-color);
}
/* Use ::before for the background image, locked to the left side */
.login-page {
    position: relative;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

/* Lock the background image to the left side */
.login-page::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 50vw;
    /* Take up the left half of the screen */
    height: 100%;
    background-image: url('../images/QF_Logo.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left center;
    opacity: 0.25;
    /* Set transparency for the logo */
    z-index: -1;
}

/* Centralized login container with a charcoal background */
.login-container {
    background-color: #333;
    /* Charcoal color */
    color: white;
    /* Change text color to white for contrast */
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 100%;
    max-width: 400px;
    z-index: 1;
}

.login-container h2 {
    margin-bottom: 20px;
}

.login-container input {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.login-container button {
    width: 100%;
    padding: 10px;
    background-color: crimson;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.login-container button:hover {
    background-color: darkred;
}

.error {
    color: red;
    margin-top: 10px;
}
/* Frame Layout */
.frame {
    display: flex;
    min-height: 100vh;
    font-family: 'Roboto', sans-serif;
    overflow: hidden;
    background-color: var(--background-color);
    color: var(--text-color);
}

/* PARENT SIDEBAR CONTAINER (in Frame) */
.sidebar-container {
    width: 250px;
    background-color: var(--sidebar-bg-color);
    color: var(--sidebar-text-color);
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 1;
    transform: translateX(0);
    /* Desktop default = visible */
    transition: transform 0.3s ease;
}

/* Applied when showSidebar is true on mobile */
.sidebar-container.visible {
    transform: translateX(0);
}

/* Content Area */
.content {
    margin-left: 250px;
    /* Desktop offset for always visible sidebar */
    flex-grow: 1;
    padding: 20px;
    background-color: var(--background-color);
    color: var(--text-color);
    height: 100vh;
    overflow-y: auto;
    transition: margin-left 0.3s ease;
}

/* Menu Button for Mobile */
.menu-button {
    position: fixed;
    top: 15px;
    left: 15px;
    z-index: 2;
    background-color: var(--button-bg-color);
    color: white;
    border: none;
    padding: 10px 15px;
    font-size: 18px;
    border-radius: 4px;
    cursor: pointer;
    display: none;
    /* hidden by default (desktop) */
}

.menu-button:hover {
    background-color: var(--button-hover-color);
}

/* Registration Popup Styling */
.registration-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--popup-bg-color);
    padding: 40px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    z-index: 1000;
}

.popup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.registration-popup h2 {
    margin-bottom: 20px;
    color: var(--text-color);
}

.close-button {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    padding: 0;
}

input {
    display: block;
    margin-bottom: 15px;
    padding: 10px;
    width: 100%;
    border: 1px solid var(--form-border-color);
    border-radius: 4px;
}

button {
    padding: 10px 15px;
    background-color: var(--button-bg-color);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

button:hover {
    background-color: var(--button-hover-color);
}

/* Theme Toggle Button */
.theme-toggle-button {
    margin-bottom: 20px;
    padding: 10px;
    cursor: pointer;
    border: 1px solid var(--form-border-color);
    border-radius: 4px;
}

/* MOBILE Styles (max-width: 768px) */
@media (max-width: 768px) {

    /* Default hidden on mobile unless 'visible' is applied */
    .sidebar-container {
        transform: translateX(-100%);
    }

    .sidebar-container.visible {
        transform: translateX(0);
    }

    .menu-button {
        display: block;
        /* show the button on mobile */
    }

    .content {
        margin-left: 0;
        /* no space for sidebar on mobile */
        padding-top: 60px;
        /* keep content from hiding behind the button */
    }
}

/* DESKTOP Styles (min-width: 769px) */
@media (min-width: 769px) {
    .sidebar-container {
        transform: translateX(0) !important;
        /* always visible on desktop */
    }

    .menu-button {
        display: none !important;
        /* no menu button on desktop */
    }

    .content {
        margin-left: 250px;
        padding-top: 20px;
    }
}
/* LocationsDashboard.css */
.locations-dashboard {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    position: relative;
}

.top-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 20px;
    margin-bottom: 20px;
    flex-wrap: nowrap;
    align-items: stretch;
}

.bottom-container {
    display: flex;
    /* Stack vertically */
    width: 100%;
    gap: 20px;
    margin-bottom: 20px;
}

.location-box {
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.large {
    flex: 3;
}

.legend-leaderboard {
    flex: 1;
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.vertical-boxes-container {
    display: flex;
    flex-direction: column;
    /* Vertical stacking */
    gap: 20px;
    width: 100%;
}

.vertical {
    flex: none;
    /* Ensure equal height */
    cursor: default;
    /* No hover effect for static boxes */
}

h2,
h3 {
    margin: 0 0 10px;
    color: crimson;
}

p {
    margin: 5px 0;
    font-size: 16px;
}

/* Color coding for financial metrics */
.revenue {
    color: darkgreen;
    font-weight: bold;
}

.expense {
    color: darkred;
    font-weight: bold;
}

.profit {
    color: green;
    font-weight: bold;
}

/* Styling for grid layout to organize data visually */
.data-group {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    align-items: center;
}

.data-label {
    font-weight: bold;
}

.data-value {
    text-align: right;
}

/* Improved box layout for a more attractive design */
.location-box .data-block {
    background-color: #f9f9f9;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Highlight progress ahead */
.progress-ahead {
    color: #28a745;
    font-weight: bold;
}

/* Highlight progress behind */
.progress-behind {
    color: #dc3545;
    font-weight: bold;
}

/* Animation for active location */
.location-box.large {
    animation: highlightBox 0.5s ease;
}

@keyframes highlightBox {
    0% {
        box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    }

    100% {
        box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
    }
}

/* Legend and leaderboard styling */
.legend-title {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 10px;
}

.leaderboard-list {
    margin-top: 15px;
}

.leaderboard-item {
    font-weight: bold;
    margin: 5px 0;
}

.leaderboard-item.first {
    color: gold;
}

.leaderboard-item.second {
    color: silver;
}

.leaderboard-item.third {
    color: #cd7f32;
}
/* Main content layout for Employees */
.employee-dashboard {
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: #f5f5f5;
    color: #333;
}

/* Header for Employee KPI section */
.employee-header {
    font-size: 24px;
    font-weight: normal;
    margin-bottom: 20px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
}

/* Table Container for Employee KPI Data */
.employee-table-container {
    background-color: white;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-height: calc(100vh - 160px);
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}


/* Responsive adjustments */
@media (max-width: 768px) {

    td,
    th {
        padding: 6px;
        font-size: 12px;
    }
}

.button-container {
    display: block;
}

.period {
    font-size: xx-large;
    font-weight: bold;
    padding: 2rem;
}

/* Customize scrollbar styling */
.employee-table-container::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

.employee-table-container::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 5px;
}

.employee-table-container::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}

.employee-table-container::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}